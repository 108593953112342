import React from "react";

import "../assets/styles/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <p className="disclaimer text-white text-justify">
        “La rentabilidad es variable, por lo que nada garantiza que las rentabilidades
        pasadas se repitan en el futuro. Infórmese sobre la rentabilidad de su Fondo de
        Pensiones, las comisiones y la calidad de servicio de las AFP en el sitio web de
        la Superintendencia de Pensiones:{" "}
        <a href="http://www.spensiones.cl"> www.spensiones.cl</a>”. Fuente: Inversiones y
        Rentabilidad de los Fondos de Pensiones correspondiente a noviembre 2024. Periodo
        correspondiente a diciembre 2023 — noviembre 2024. Rentabilidad de la cuota
        expresada en forma real: Fondo A: 18,12%, Fondo B: 15,24%, Fondo C: 10,98%, Fondo
        D: 8,95%, Fondo E: 8,80% (tercer lugar). Estructura de comisiones por depósitos de
        cotizaciones vigentes a diciembre de 2024 – Capital: 1,44%, Cuprum: 1,44%,
        Habitat: 1,27%, Modelo: 0,58%, Planvital: 1,16%, Provida: 1,45%, Uno: 0,49% – Para
        afiliados dependientes, independientes y voluntarios. Número de afiliados por AFP
        vigente según Informe Estadístico Mensual de Afiliados y Cotizantes de la
        Superintendencia de Pensiones (2.804.939 afiliados al 30 de septiembre de 2024).
        Primer lugar en experiencia de servicio según ranking PXI – Praxis Xperience Index
        2024 para el Sector AFP.
      </p>
    </footer>
  );
};

export default Footer;
